.fac_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.fac_container {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.first_title {
  font-size: 68px;
  font-family: "MonumentExtended";
  font-weight: bold;
}

.first_title sup {
  font-size: 40px;
}

.first_subtitle {
  color: #CCFD34;
  font-size: 24px;
  font-family: "NeueMachina";
  margin-bottom: 40px;
  white-space: nowrap;
}

.first_list {
  font-family: "MonumentExtended";
  font-weight: lighter;
  font-size: 14px;
  line-height: 19px;
  padding-left: 25px;
}

.first_listItem {
  margin-bottom: 32px;
  padding-left: 5px;
  width: 369px;
}

.first_listItem::marker {
  font-family: "MonumentExtended";
  font-weight: bold;
}

.first_listItem span {
  font-weight: bold;
}

.first_desc {
  opacity: 1;
  font-weight: normal !important;
  opacity: 0.5;
}

.first_imageContainer {
  width: 100%;
  max-height: 80vh;
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin-left: 10vw;
}

.first_cover {
  max-height: 80vh;
  max-width: 100%;
  object-fit: cover;
}

.first_inner_image {
  position: absolute;
  top: 100px;
  left: -115px;
  max-height: 366px;
  max-width: 637px;
  width: 90%;
  object-fit: cover;
  border: 7px solid #000000;
}

.nextButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 25px;
  font-size: 12px;
  font-family: "NeueMachina";
  font-weight: lighter;
  align-self: flex-end;
}

.first_blk {
  display: flex;
  justify-content: center;
  align-items: center;
}

.first_arrow {
  transform: translateY(-3px);
  margin-left: 20px;
  cursor: pointer;
}

.block_back {
  position: absolute;
  transform: translateY(-3px);
}

.next-button-container {
  display: flex;
  column-gap: 20px;
  align-items: center;
  width: max-content;
}

.next-button-container.contained .next-button {
  background-color: #CCFD34;
  color: black;
}

.next-button-container.contained:hover .next-button {
  background-color: transparent;
}

.next-button-container.contained:hover .next-button__txt {
  color: white;
}

.next-button-container:hover .next-button {
  background-color: #CCFD34;
}

.next-button-container:hover .next-button__txt {
  color: black;
}

.next-button-container:hover #arrow-head {
  opacity: 1;
}

.next-button {
  position: relative;
  line-height: 0px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.next-button__txt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  font-family: "NeueMachina";
  font-weight: lighter;
  opacity: 0.8;
  font-size: 12px;
}

@media screen and (max-width: 1640px) {
  .first_cover {
    max-height: 70vh;
    margin-top: 50px;
  }

  .first_title {
    font-size: 48px;
    margin-top: 45px;
  }

  .first_title sup {
    font-size: 30px;
  }

  .first_subtitle {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .first_list {
    font-size: 12px;
    line-height: 19px;
    padding-left: 25px;
  }

  .first_listItem {
    margin-bottom: 22px;
    padding-left: 5px;
    width: 369px;
  }

  .first_inner_image {
    max-height: 40%;
    width: 90%;
    object-fit: cover;
    border: 7px solid #000000;
  }
}

@media screen and (max-width: 1320px) {
  .first_listItem {
    width: 220px;
  }
}

.slider-next {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 117px;
  width: 117px;
  height: 44px;

}

.slider-next svg {
  position: absolute;
  width: 100%;
  height: 100%;
}

.slider-next:hover {
  background-color: #CCFD34;
}

svg {
  cursor: pointer;
}
.sideBar {
  height: 100vh;
  padding-left: 19px;
  width: 122px;
  overflow: hidden;
  cursor: pointer;
  position: fixed;
  z-index: 500;
  transition: all 0.25s;
}
.sideBar.active {
  width: 100%;
}
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.menu {
  height: 100%;
  background-color: #fd614d;
  display: flex;
  transition: all 0.25s;
}
.menu.active {
  width: 100%;
}
.img {
  margin-top: 8px;
  margin-bottom: 8px;
  max-width: 103px;
}
.burgerMenu {
  width: 103px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.navigation {
  padding-top: 90px;
  padding-bottom: 70px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.navLink {
  font-size: 138px;
  position: relative;
  display: inline-block !important;
  font-family: "NeueMachina";
  font-weight: bold;
}
.navLink::before {
  content: "";
  width: 0;
  height: 8px;
  background-color: #000000;
  position: absolute;
  left: 0;
  top: 50%;
  transition: all 0.3s;
}
.navLink:hover::before {
  width: calc(100% + 60px);
}
.navLink:hover::after {
  content: "*";
  position: absolute;
  left: 100%;
  top: 0;
}

.lastLink {
  width: 85vw;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.firstLink {
  width: 85vw;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.nav-home-container {
  position: relative;
  transition: all 0.3s ease;
  transform: translate(-25px, 30px);
}

.nav-home-container svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.nav-home-container .active-home {
  opacity: 0;
  transition: all 0.3s ease;
}

.nav-home-container .inactive-home {
  transition: all 0.3s ease;
}

.nav-home-container:hover .active-home {
  opacity: 1;
}

.nav-home-container:hover .inactive-home {
  opacity: 0;
}

/* responsive */

@media screen and (max-width: 1125px) {
  .navLink {
    font-size: 100px;
  }
}

@media screen and (max-height: 900px) {
  .navLink {
    font-size: 100px;
  }
}

@media screen and (max-width: 992px) {
  .sideBar {
    display: none;
  }
}

@media screen and (max-height: 580px) {
  .navigation {
    overflow-y: scroll;
  }
}
/* responsive */

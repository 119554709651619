.locationPage {
  background-color: #151619;
  display: flex;
  flex: 1;
  min-height: 100vh;
  flex-direction: row;
  color: #fff;
}

.titleTxt {
  color: #ffffff;
  font-size: 123px;
  font-family: "MonumentExtended";
  line-height: 106px;
  margin-bottom: 20px;
  font-weight: bold;
}

.titleTxt span {
  display: block;
  white-space: nowrap;
}

.desc_block h2 {
  font-family: "NeueMachina";
  font-weight: bold;
  font-size: 68px;
}

.desc_block p {
  font-family: "MonumentExtended";
  font-weight: lighter;
  font-size: 14px;
}

.desc_block span {
  display: block;
  font-family: "MonumentExtended";
  font-weight: bold;
}

.mainLocation {
  /* position: relative; */
  padding: 69px 140px 138px 278px;
  padding-left: 278px;
  padding-bottom: 149px;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.textContent {
  z-index: 200;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.imageContainer {
  width: 55%;
  height: 80vh;
  max-height: 730px;
  position: absolute;
  top: 69px;
  right: 140px;
}

.imageContainer .cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.snowflakeIcon {
  position: absolute;
  top: 20%;
  right: -50px;
  max-width: 183px;
}

.swiperWrap {
  display: none !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.locationPage .swiperNavigation {
  margin-top: 30px;
}

.transparant-text {
  opacity: 0.7;
  font-weight: lighter !important;
}

.arrow {
  width: 20px;
  height: 20px;
  background-color: red;
}

.back_box {
  position: absolute;
  transform: translateY(-2px);
}

.map-image {
  max-width: 558px;
  width: 50%;
  position: absolute;
  right: 8%;
  align-self: flex-end;
  z-index: 99;
}

.location-foter {
  position: absolute;
  max-width: 20%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  font-size: 12px;
}

.location-foter .location {
  opacity: 0.6;
}

/* responsive */

@media screen and (max-width: 1640px) {
  .mainLocation {
    padding: 30px 120px 30px 227px;
  }

  .textContent {
    gap: 22px;
  }
  .titleTxt {
    font-size: 90px;
    line-height: 80px;
  }

  .desc_block h2 {
    font-size: 58px;
  }

  .desc_block p {
     margin-bottom: 0;
  }

  .map-image {
    max-width: 400px;
  }

  .imageContainer {
    max-height: 70%;
  }
}

@media screen and (max-width: 1500px) {
  .locationDescriptionTxt {
    margin-bottom: 22px;
  }

  .mainLocation {
    padding: 59px 140px 65px 208px;
  }

}

@media screen and (max-width: 1440px) {
  .titleTxt {
    font-size: 86px;
  }
}

@media screen and (max-width: 1320px) {
  .titleTxt {
    font-size: 70px;
    line-height: 65px;
  }

  .textContent {
    gap: 22px;
    min-height: 500px;
  }

  .snowflakeIcon {
    max-width: 100px;
    right: -25px;
  }

  .imageContainer {
    right: 30px;
  }
}

@media screen and (max-width: 992px) {
  .mainLocation {
    padding: 0 16px 0 16px;
    align-items: flex-start;
    min-height: auto;
    margin-bottom: 45px;
  }

  .titleTxt {
    font-size: 38px;
    line-height: 40px;
    padding-top: 85px;
  }

  .textContent {
    min-height: 420px;
  }

  .textContent .desc_block {
    display: none;
  }

  .locationPage {
    padding-top: 86px;
    display: flex;
    flex-direction: column;
  }

  .imageContainer {
    right: 16px;
    top: 111px;
    width: 70%;
    height: 100% !important;
    max-height: 285px;
  }
  
  .map-image {
    max-width: 310px;
    right: unset;
    width: auto;
  }

  .location-foter {
    display: none;
  }

  .snowflakeIcon {
    display: none;
  }

  .swiperWrap {
    display: block !important;
  }

  .desc_block {
    text-align: center;
    max-width: 80%;
    margin: auto;
  }

  .desc_block h2 {
    font-size: 52px;
  }
}

@media screen and (max-width: 370px) {
  .titleTxt {
    font-size: 30px;
  }
}

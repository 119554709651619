.landingPage {
  background-color: #151619;
  display: flex;
  flex: 1;
  height: 100vh;
  flex-direction: row;
  color: #fff;
}

.mainContent {
  position: relative;
  display: flex;
  flex: 1;
  color: #fff;
  padding: 104px 140px 139px 147px;
  margin-left: 128px;
}
.LandingPageImgContainer {
  position: relative;
  height: 100%;
  width: auto;
}

.LandingPageImg {
  /* height: 100%;
  width: auto; */
  height: 100%;
  width: 100%;
}
.textsContainer {
  display: flex;
  flex: 1;
  min-width: 200px;
  flex-direction: column;
  /* background-color: rgb(53, 58, 47); */
  z-index: 100;
  align-self: end;
  margin: auto;
}
.topTxt {
  color: #ffffff;
  font-size: 44px;
  font-weight: 400;
  font-family: "NeueMachina";
}

.landingTitleTxt {
  color: #ffffff;
  font-size: 190px;
  line-height: 190px;
  font-family: "MonumentExtended" !important;

}

.welcomeTxt {
  color: #ffffffcc;
  font-family: "HelveticaNeueMedium";
  font-family: Helvetica, sans-serif;
  margin-bottom: 24px;
}

.landingDescriptionTxt {
  color: #ffffffcc;
  font-size: 16px;
  line-height: 24px;
  font-family: Helvetica, sans-serif;
  /* margin-bottom: 160px; */
}
.signUpbtn {
  display: none;
  background-color: #fd614d;
  width: 142px;
  height: 47px;
  border: none;
  outline: none;
  color: #151619;
  font-size: 16px;
  margin-bottom: 60px;
  margin-top: 24px;
  cursor: pointer;
  font-family: "MonumentExtended";
  font-weight: 400;
}

.LandingSnowflakeIcon {
  position: absolute;
  top: 50px;
  right: 0;
  transform: translateX(50%);
}

.landingTitleTxt br {
  display: none;
}

/* responsive */

@media screen and (max-width: 1500px) {
  .landingTitleTxt {
    font-size: 150px;
    line-height: 150px;
  }
}

@media screen and (max-width: 992px) {
  .mainContent {
    margin-top: 100px;
    justify-content: flex-end;
    padding: 16px !important;
    margin-left: 0;
  }

  .textsContainer {
    margin-right: 0 !important;
  }

  .topTxt {
    font-size: 24px;
    line-height: 20px;
  }

  .landingTitleTxt {
    font-size: 118px;
    line-height: 107px;
  }

  .landingTitleTxt_br {
    display: block;
  }

  .welcomeTxt {
    font-size: 18px;
    line-height: 24px;
  }

  .landingDescriptionTxt {
    font-size: 14px;
    max-width: 358px;
  }

  .LandingPageImgContainer {
    position: absolute !important;
    top: 0px;
  }
  
  .LandingPageImg {
    max-width: 400px;
    max-height: 373px;
    width: 400px;
    height: 373px;
  }

  .LandingSnowflakeIcon {
    top: 10%;
    left: 0;
    width: 68px;
    aspect-ratio: 1/1;
    transform: translateX(-50%);
  }

  .signUpbtn {
    display: block;
    width: 100%;
    margin-bottom: 0;
  }

}

@media screen and (max-width: 700px) {
  .landingTitleTxt br {
    display: block;
  }
}

@media screen and (max-width: 500px) {
  div.landingPage {
    height: auto;
    min-height: 100vh;
  }

  .LandingPageImg {
    max-width: 266px;
    height: 373px;
  }
}
.ratesPage {
  background-color: #151619;
  display: flex;
  flex: 1;
  min-height: 100vh;
  flex-direction: row;
  color: #fff;
}

.ratesMainContent {
  display: flex;
  flex: 1;
  column-gap: 127px;
  color: #fff;
  padding: 69px 140px 138px 147px;
  margin-left: 128px;
}

.rates-description__title {
  font-size: 80px;
  line-height: 80px;
  text-transform: uppercase;
  word-break: break-all;
  font-family: "MonumentExtended";
}

.rates-description__subTitle {
  font-size: 24px;
  line-height: 59px;
  font-family: "NeueMachina";
  color: #ccfd34;
  font-weight: 500;
}

.rates-description__desc {
  font-size: 14px;
  line-height: 19px;
  font-family: "MonumentExtended";
  font-weight: lighter;
  opacity: 0.5;
}

.rates-description {
  flex: 1;
  max-width: 563px;
  align-self: center;
}

.rates-description .next-button-container {
  margin-top: 25px;
  text-transform: capitalize;
}

.rates-cards {
  height: 900px;
  flex: 1;
  display: flex;
  align-self: center;
  gap: 16px;
}

.rates-cards .boldTxt {
  font-size: 12px;
}

.rates-cards__row {
  display: flex;
  flex-direction: column;
  gap: 33px;
}

.rates-cards__row > .rate-card {
  flex: 1;
}

.rate-card {
  position: relative;
  max-width: 398px;
  padding: 22px 32px;
  box-shadow: 0px 0px 0px 8px #ffffff inset;
  cursor: pointer;
}

.rate-card.rate-card--tall {
  height: 100%;
  padding: 32px 32px;
}

.rate-card__image-container {
  min-width: 250px;
  margin-bottom: 25px;
}

.rate-card .rate-card__title {
  margin-bottom: 8px;
  font-size: 32px;
  line-height: 35px;
  font-family: "NeueMachina";
  font-weight: bold;
}

.rate-card .rate-card__desc {
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 19px;
  opacity: 0.5;
  font-family: "MonumentExtended";
}

.rate-card .rate-card__price {
  font-size: 44px;
  margin-top: 10px;
  line-height: 36px;
  font-family: "NeueMachina";
}

.rate-card__price .call-price {
  color: #fff;
  text-decoration: underline;
}

.rate-card .rate-card__price sup {
  font-size: 16px;
  color: #ccfd34;
  font-weight: lighter;
  margin-left: 10px;
}

.rate-card__image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.rate-card:hover .rate-card-hover:not(:empty) {
  opacity: 1;
}

.rate-card-hover {
  position: absolute;
  top: 8px;
  left: 8px;
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  background-color: #fd614d;
  opacity: 0;
  transition: all 0.3s ease;
  pointer-events: none;

  display: flex;
  flex-direction: column;

  padding: 30px;
  gap: 25px;
}

.rate-card-hover.rate-card-hover--centered {
  justify-content: center;
}

.card-list-title {
  margin-bottom: 8px;
  text-align: center;
  opacity: 0.7;
  font-family: "MonumentExtended";
}

.rate-card-hover ul {
  width: 100%;
  list-style: none;
}

.rate-card-hover ul li {
  display: flex;
  justify-content: space-between;
}

.rate-card-hover ul li:not(:last-of-type) {
  margin-bottom: 12px;
}

.rate-card--tall .card-list-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-list-item__title {
  font-size: 20px;
  font-family: "MonumentExtendedRegular";
}

.card-list-item__price sup {
  font-size: 12px;
  font-weight: 100;
}

.card-list-item__price {
  font-size: 32px;
  font-family: "NeueMachina";
  font-weight: bold;
}

.boxesContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.sectionOne {
  display: flex;
  flex: 1;
}
.sectionTwo {
  display: flex;
  flex: 1;
}

.bigBox {
  display: flex;
  /* flex: 2; */
  flex: 1;
  /* width: calc(66.66% + 16px); */

  margin: 0px 8px 16px 8px;
  border: 8px solid #ffffff;
  padding: 34px;
  flex-direction: column;
  justify-content: end;
}

.smallBox {
  display: flex;
  width: calc(33.33% - 16px);
  /* flex: 1; */
  margin: 0px 8px 16px 8px;
  border: 8px solid #ffffff;
  padding: 34px;
  /* align-items: end; */
  justify-content: end;
  flex-direction: column;
}
.box {
  display: flex;
  flex: 1;
  margin-right: 4px;
  margin: 16px 8px 0px 8px;
  border: 8px solid #ffffff;
  padding: 34px;
  /* align-items: end; */
  justify-content: end;
  flex-direction: column;
}

.boldTitle {
  font-size: 68px;
  font-family: "NeueMachina";
  font-weight: bold;
  /* margin-bottom: -60px; */
}
.redText {
  font-size: 70px;
  color: #fd614d;
  font-weight: bold;
}
.redSubText {
  font-size: 28px;
}

.greenTxt {
  font-size: 16px;
  color: #ccfd34;
}
.descriptionText {
  color: #ffffffcc;
  font-size: 14px;
  margin-bottom: 22px;
  font-family: "MonumentExtended";
  font-weight: light;
}
.boldText {
  font-weight: bold;
  color: #ffffff;
}

.mobile-rates-main-content {
  display: none;
}

.slider-box {
  position: relative;
  max-width: 44px;
  line-height: 0;
  cursor: pointer;
}

.slider-box__text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.slider-box.active {
  background-color: #ccfd34;
  color: #151619;
}

.slider-box-container {
  margin-block-start: 60px;
  display: flex;
  justify-content: center;
  column-gap: 16px;
}

@media screen and (max-width: 1650px) {
  .ratesMainContent {
    padding-top: 20px;
    /* padding-left: 30px; */
    padding-right: 30px;
    gap: 80px;
  }
  .rates-description {
    max-width: 500px;
  }

  .rates-description__title {
    font-size: 55px;
    line-height: 60px;
  }

  .rates-description__subTitle {
    font-size: 24px;
    line-height: 49px;
  }

  .rates-description__desc {
    display: inline;
    font-size: 14px;
    line-height: 19px;
  }

  .rates-cards {
    height: 650px;
  }

  .rate-card {
    padding: 20px;
  }

  .rates-cards__row {
    row-gap: 5px;
  }

  .rate-card.rate-card--tall {
    padding: 20px;
    /* min-height: 900px; */
  }

  .rate-card.rate-card--tall .rate-card__image-container {
    height: 70%;
  }

  .rate-card__image-container {
    min-width: 250px;
    margin-bottom: 15px;
  }

  .rate-card .rate-card__title {
    margin-bottom: 8px;
    font-size: 24px;
    line-height: 20px;
    font-family: "NeueMachina";
    font-weight: bold;
  }

  .rate-card .rate-card__desc {
    margin-bottom: 15px;
    font-size: 12px;
    line-height: 15px;
    font-family: "MonumentExtended";
  }

  .rate-card .rate-card__price {
    font-size: 34px;
    line-height: 36px;
    font-weight: bold;
  }

  .card-list-title {
    margin-bottom: 5px;
  }

  .rate-card-hover {
    padding: 15px;
  }

  .rate-card-hover ul li:not(:last-of-type) {
    margin-bottom: 8px;
  }

  .card-list-item__title {
    font-size: 16px;
  }

  .card-list-item__price sup {
    font-size: 12px;
    font-weight: 100;
  }

  .card-list-item__price {
    font-size: 25px;
  }
}

@media screen and (max-width: 1550px) {
  .rate-card-hover {
    gap: 10px;
  }
}

@media screen and (max-width: 1400px) {
  .ratesMainContent {
    margin-left: 20px;
  }

  .rates-description__title {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 10px;
  }

  .rates-description__subTitle {
    font-size: 20px;
    line-height: 25px;
  }

  .rates-description__desc {
    font-size: 12px;
    line-height: 12px;
  }
}

@media screen and (max-width: 1100px) {
  .rate-card__image-container {
    min-width: 210px;
  }
}

@media screen and (max-width: 992px) {
  .ratesMainContent {
    display: none;
  }

  .mobile-rates-main-content {
    margin-top: 100px;
    display: block;
    padding: 16px;
    width: 100%;
    /* overflow: hidden; */
  }

  .rates-description {
    margin-bottom: 52px;
  }

  .rates-description .next-button-container {
    display: none;
  }

  .rates-description__title {
    font-size: 32px;
    line-height: 36px;
  }

  .rates-description__subTitle {
    font-size: 24px;
    line-height: 59px;
  }

  .rates-description__desc {
    font-size: 14px;
  }

  .rates-description .boldTxt {
    display: none;
  }

  .rate-card__image-container {
    height: 146px;
  }

  .rate-card .boldTxt {
    font-size: 12px;
    font-weight: lighter;
    opacity: 0.6;
  }

  .rate-card {
    margin: auto;
  }

  .mobile-rates-title {
    font-size: 30px;
    line-height: 132px;
    text-transform: uppercase;
    font-family: "MonumentExtended";
    font-weight: bold;
  }

  .rate-slider-container {
    max-width: 500px;
    margin: auto;
    overflow: hidden;
    /* display: flex; */
    /* overflow: hidden; */
  }

  .boldTitle {
    word-break: break-all;
    line-height: 40px;
    margin-bottom: 40px;
  }

  .box {
    max-width: 368px;
    margin: auto;
  }

  .card-list-item__title {
    font-size: 15px;
  }

  .card-list-item__price {
    font-size: 18px;
  }

  .card-list-item__price sup {
    font-size: 12px;
    position: relative;
    top: 3px;
  }

  .card-list-title {
    font-size: 11px;
    opacity: 0.7;
  }
}

@media (max-width: 400px) {
  .rate-card {
    margin: 0 5px;
  }
}

.overlay {
    display: grid;
    place-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #15161980;
    z-index: 99999;
    animation: fadeIn 200ms ease-out;
    padding: 0 20px 0 20px;
}

.dialog {
    position: relative;
    max-width: 512px;
    height: 261px;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #151619;
    border: #FD614D solid 11px;
}

.dialog__close {
    position: absolute;
    top: 10px;
    right: 10px;
}
.dialog__message {
    text-align: center;
    font-size: 44px;
    line-height: 48px;
    font-family: "MonumentExtended";
    font-weight: bold;
}


@keyframes fadeIn {
    from {
      opacity: 0;
    }
  }
  
@font-face {
  font-family: "NeueMachina";
  src: url("./assets/fonts/PPNeueMachina-InktrapRegular.otf");
}

@font-face {
  font-family: "NeueMachina";
  src: url("./assets/fonts/PPNeueMachina-PlainUltrabold.otf");
  font-weight: bold;
}

@font-face {
  font-family: "MonumentExtendedRegular";
  src: url("./assets/fonts/PPMonumentExtended-Regular.otf");
}

@font-face {
  font-family: "MonumentExtended";
  font-weight: bold;
  src: url("./assets/fonts/PPMonumentExtended-Black.otf");
}

@font-face {
  font-family: "MonumentExtended";
  font-weight: lighter;
  src: url("./assets/fonts/PPMonumentExtended-Light.otf");
}

@font-face {
  font-family: "HelveticaNeueMedium";
  font-weight: medium;
  src: url("./assets/fonts/Helvetica\ Neue\ Medium\ Extended.otf");
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: #151619;
}

a {
  text-decoration: none;
  color: #000000;
}

::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.contactPage {
  background-color: #151619;
  display: flex;
  flex: 1;
  height: 100vh;
  flex-direction: row;
  color: #fff;
}
.leftMenu {
  width: 128px;
  display: flex;
  height: 100vh;
  background-color: #ccfd34;
  color: #fff;
}
.contactMainContent {
  position: relative;
  display: flex;
  flex: 1;
  color: #fff;
  margin-left: 85px;
  padding: 69px 140px 138px 147px;
}
.ContactPageImgContainer {
  flex: 1;
  height: 100%;
  width: auto;
}

.contactPageImage {
  /* height: 100%;
    width: auto; */
  height: 100%;
  width: 100%;
}

.contactPageImage--mobile {
  display: none;
}

.contentWrapper {
  flex: 1;
  display: flex;
  /* align-items: center; */
}

.textsContainer {
  display: flex;
  flex: 1;
  max-width: 500px;
  /* min-width: 200px; */
  flex-direction: column;
  /* background-color: rgb(53, 58, 47); */
  z-index: 100;
  align-self: center;
  /* margin-right: 24px; */
  margin: 0;
}

.contactTitleTxt {
  color: #ffffff;
  /* font-size: 6.87vw; */
  white-space: nowrap;
  font-size: 100px;
  font-family: "MonumentExtended";
  font-weight: bold;
  z-index: 1;
}

.inputContainer {
  width: 100%;
}

.inputContainer input,
.inputContainer textarea {
  font-family: "MonumentExtended";
}

.messageBtn {
  background-color: #ccfd34;
  width: 142px;
  height: 47px;
  border: none;
  outline: none;
  color: #151619;
  font-size: 16px;
  cursor: pointer;
  font-family: "MonumentExtended";
}

.nameInputsCont {
  display: flex;
  gap: 15px;
  width: 100%;
  /* justify-content: space-between; */
  /* width: 536px; */
  margin-bottom: 24px;
}

.nameInput {
  height: 42px;
  flex: 1;
  width: 100%;
}

.inputStyle {
  border: none;
  outline: none;
  padding-left: 24px;
  font-size: 16px;
}

.messageInput {
  /* width: 536px; */
  height: 118px;
  padding-top: 24px;
  width: 100%;
}
.marginR {
  margin-right: 16px;
}
.btnContainer {
  display: flex;
  /* width: 536px; */
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 65px;
  cursor: pointer;
}
.checkbox {
  -webkit-appearance: none;
  appearance: none;
  min-width: 24px;
  min-height: 24px;
  border-radius: 2px;
  border: 1px solid #ffffff;
  outline: none;
  cursor: pointer;
}

.checkbox:checked {
  background-color: transparent;
  position: relative;
}

.checkbox:checked::before {
  content: "\2714\fe0e";
  font-size: 1em;
  color: #fd614d !important;
  position: absolute;
  right: 4px;
  top: 1px;
}

.centerAlign {
  display: flex;
  align-items: center;
  justify-content: center;
}
.spamTxt {
  color: #ffffff;
  font-size: 12px;
  margin-left: 11px;
  font-family: "MonumentExtended";
  font-weight: light;
}
.descTxt {
  color: #ffffff;
  font-size: 14px;
  width: 50%;
  font-family: "MonumentExtended";
}

.boldTxt {
  font-family: "MonumentExtended";
  font-weight: bold;
}

.textCont {
  display: flex;
  /* width: 536px; */
  justify-content: space-between;
  display: flex;
}

.textCont__contact {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.socialLogosContainer {
  width: 20px;
  height: 20px;
  margin-right: 16px;
  margin-top: 34px;
}
.socialLogo {
  width: 100%;
  height: 100%;
}
.social-row, .mobile-social-row {
  display: flex;
  flex-direction: row;
}
.contactSnowflakeIcon {
  position: absolute;
  bottom: 256px;
  right: 78px;
}
.SnowflakeSmall {
  width: 48px;
  height: 48px;
  margin-top: 5px;
}

.contactTitleTxt br {
  display: none;
}

.mobile-contact {
  display: none;
  font-family: "MonumentExtended";
}

/* responsive */

@media screen and (max-width: 1500px) {
  .contentWrapper {
    padding-right: 10px;
  }

  .nameInputsCont {
    flex-direction: column;
    margin-bottom: 12px;
  }
  .marginR {
    margin-bottom: 12px;
    margin-right: 0px;
  }
  .nameInput {
    padding: 6px 24px 6px 24px;
  }
  .messageInput {
    height: 90px;
  }

  .btnContainer {
    margin-top: 12px;
    margin-bottom: 30px;
  }
  .contactMainContent {
    padding: 69px 140px 138px 147px;
    padding: 59px 120px 128px 117px;
  }

  .contactTitleTxt {
    font-size: 70px;
  }
}

@media screen and (max-width: 1400px) {
  .contactTitleTxt {
    font-size: 60px;
  }
}

@media screen and (max-width: 1300px) {
  .contactTitleTxt {
    font-size: 55px;
    white-space: unset;
  }
}

/* @media screen and (max-width: 992px) and (min-height: 900px) {
  .contentWrapper {
    max-height: 600px;
  }
} */

@media screen and (max-width: 992px) {
  .contactMainContent {
    margin-top: 100px;
    padding: 16px 36px !important;
    flex-direction: column;
    align-items: end;
    margin-left: 0;
  }

  .contentWrapper {
    width: 100%;
    max-height: 520px;
    padding-right: 0;
  }

  .ContactPageImgContainer {
    position: absolute;
    height: auto;
  }

  .contactPageImage {
    width: 266px;
    height: 193px;
  }

  .contactSnowflakeIcon {
    width: 68px;
    aspect-ratio: 1/1;
    bottom: -30px !important;
    top: auto !important;
    right: 20px !important;
    display: block !important;
  }

  .textsContainer {
    width: 100%;
    max-width: 100%;
    margin-block-start: 145px;
    align-self: flex-start !important;
  }
  .SnowflakeSmall {
    display: none;
  }

  .contactTitleTxt {
    font-size: 52px;
    line-height: 44px;
    margin-bottom: 10px;
  }

  .contactTitleTxt br {
    display: block;
    line-height: 44px !important;
  }

  div.contactPage {
    height: auto;
    min-height: 100vh;
  }

  .textCont {
    display: none;
  }

  .mobile-contact {
    display: block;
    width: 100%;
    font-size: 12px;
  }

  .darker {
    opacity: 0.7;
  }

  .mobile-contact__contacts {
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    align-items: center;
    justify-content: space-between;
  }

  .mobile-contact__contacts .descTxt {
    text-align: right;
  }

  .mobile-contact__contacts .phone {
    font-weight: bold;
  }

  .contact__link {
    color: #ffffff !important;
    font-family: "MonumentExtended";
    font-size: 12px;
    white-space: nowrap;
  }

  .social-row {
    display: none;
  }
}

@media screen and (max-width: 650px) {
  .contactPageImage {
    display: none;
  }

  .contactPageImage--mobile {
    display: block;
  }
}

@media screen and (max-width: 400px) {
  .contactMainContent {
    padding: 16px !important;
  }
}
.facilitiesPage .swiper {
  /* overflow: visible; */
}

.fac_mobile {
  width: 100%;
  padding: 106px 16px 50px 16px;
}

.fac_mobile_cover {
  width: 100%;
  min-height: 408px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.fac_mobile_cover.second-cover {
  justify-content: unset;
  align-items: unset;
  margin-bottom: 30px;
}

.second-cover .fac_mobile_title {
  margin-top: 50px;
}

.fac_mobile_cover img {
  max-width: 100%;
}

.fac_mobile_cover .fac_mobile_cover_second {
  position: absolute;
  max-width: 70%;
}

.fac_mobile_head {
  padding-left: 8px;
}

.fac_mobile_title {
  font-size: 65px;
  font-family: "MonumentExtended";
  font-weight: bold;
}
.fac_mobile_title sup {
  font-size: 40px;
}
.fac_mobile_subtitle {
  color: #CCFD34;
  font-size: 24px;
  font-family: "NeueMachina";
  margin-bottom: 40px;
}

.fac_pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  font-family: "NeueMachina";
}

.fac_pagination .swiperNavigation {
  padding-bottom: 0;
}

.fac_page {
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  cursor: pointer;
}

.fac_page_cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.fac_page_cover.second {
  max-width: 85%;
  /* min-width: 300px; */
  left: auto;
  right: 0;
}

.fac_page_cover_second_image {
  position: absolute;
  width: 305px;
  top: 280px;
  left: 0;
  border: 3px solid black;
}

.fac_page span {
  transform: translateY(2px);
}
.fac_page_back {
  position: absolute;
}

.fac_page.active {
  background-color: #c05043;
}

.fac_content {
  margin-top: 32px;
  font-family: "MonumentExtended";
  font-weight: lighter;
  font-size: 14px !important;
}

.fac_content.flash {
  animation: flash 0.75s forwards;
}

.green_snowFlake {
  position: absolute;
  right: 36px;
  bottom: 0;
  transform: translateY(50%);
  z-index: 20;
}

.fac_page_cover.fac_page_cover--mobile {
  display: none;
}

@keyframes flash {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 100;
  }
}

@media screen and (max-width: 992px)  {
  .fac_content .first_listItem {
    width: auto;
    max-width: 400px;
  }
}

@media screen and (max-width: 850px) {
  .fac_mobile_title {
    font-size: 62px;
    line-height: 50px;
  }
  .fac_mobile_title sup {
    font-size: 25px;
  }

  .fac_mobile_title--big {
    font-size: 65px;
  }

  .fac_mobile_subtitle {
    font-size: 18px;
    line-height: 52px;
  }
  
  .fac_page_cover.second {
    max-height: 362px;
  }
}

@media screen and (max-width: 450px) {
  .fac_page_cover.first {
    display: none;
  }

  .fac_page_cover.fac_page_cover--mobile {
    display: block;
  }
}

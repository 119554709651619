.mobileMenu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 87px;
  z-index: 500;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 7px;
  padding-inline: 16px;
  background-color: #151619;
}

.logo {
  display: flex;
}

.iBlock {
  height: 24px;
  margin: auto;
  background-color: #FD614D;
  flex-shrink: 0;
}

.logo .img {
  transform: rotate(-90deg);
  max-width: 30px;
  margin: 0 !important;
}

.logo img:first-of-type {
  transform: rotate(-90deg) translateY(5px);
}

.mobileMenu .img {
  max-width: 24px;
  cursor: pointer;
}

.mobileNav {
  width: 100%;
  height: 100vh;
  background-color: #FD614D;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  transform: translateY(-100vh);
  animation: menu-in 0.25s forwards;
  padding-top: 130px;
  padding-inline: 16px;
  overflow: auto;
}

.close {
  max-width: 18px;
  position: absolute;
  right: 28px;
  top: 55px;
  cursor: pointer;
}

.mobileNavigation {
  max-width: 100px;
}

.mobileNavigation .navLink {
  font-size: 62px;
}

.mobileNavigation .navLink::after,
.mobileNavigation .navLink::before {
  display: none;
}

.headerContact {
  font-family: "MonumentExtended";
  font-weight: lighter;
  color: #000000;
  margin-top: 96px;
  margin-bottom: 10px;
  font-size: 12px;
}

.headerContact span {
  font-weight: bold;
}

.headerContact a {
  font-weight: bold;
  text-decoration: line-through;
}

.contacts {
  display: flex;
  gap: 10px;
  margin-bottom: 48px;
  font-size: 12px;
}

.contacts a {
  display: block;
}

.tel {
  font-family: "MonumentExtended";
  font-weight: bold;
}
.mail {
  font-family: "MonumentExtended";
  font-weight: lighter;
}

@keyframes menu-in {
  to {
    transform: translate(0);
  }
}

@media screen and (max-width: 992px) {
  .mobileMenu {
    display: flex;
  }
}

@media screen and (max-width: 400px) {
  .mobileNavigation .navLink {
    font-size: 52px;
  }
}

@media screen and (max-width: 335px) {
  .mobileNavigation .navLink {
    font-size: 42px;
  }
}

.scnd_fac_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.scnd_imageContainer {
  width: 100%;
  display: flex;
  flex-basis: 1041px;
  justify-content: flex-start;
  position: relative;
  margin-right: 300px;
}

.scnd_cover {
  /* max-height: 80vh; */
  object-fit: cover;
  max-width: 120%;
}

.scnd_inner_image {
  position: absolute;
  top: 30%;
  right: -30%;
  max-height: 366px;
  max-width: 699px;
  object-fit: cover;
  border: 7px solid #000000;
}

.scnd_fac_wrapper .nextButton {
  align-self: flex-end;
}

.scnd_fac_wrapper .back_image {
  max-width: 50%;
}

@media screen and (max-width: 1640px) {
  .scnd_imageContainer {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    position: relative;
  }

  .scnd_cover {
    max-height: 70vh;
    margin-top: 50px;
  }

  .scnd_inner_image {
    max-height: 40%;
    width: 90%;
    object-fit: cover;
    border: 7px solid #000000;
  }

  .scnd_inner_image {
    max-width: 400px;
  }
}

@media screen and (max-width: 1320px) {
  .scnd_imageContainer {
    margin-right: 250px;
  }
}

@media screen and (max-width: 1220px) {
  .scnd_imageContainer {
    margin-right: 150px;
  }
}

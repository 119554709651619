.swiperNavigation {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 28px;
  cursor: pointer;
  padding-bottom: 50px;
}

.index {
  font-family: "NeueMachina";
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  text-transform: uppercase;
}

.back_box {
  position: absolute;
  transform: translateY(-2px);
}

.navigate-next {
  display: flex;
  gap: 28px;
}
.facilitiesPage {
  background-color: #151619;
  display: flex;
  flex: 1;
  min-height: 100vh;
  flex-direction: row;
  color: #fff;
}

.facilities {
  padding: 69px 50px 138px 278px;
}

.loader {
  position: absolute;
  width: 0;
  height: calc(100vh - 158.26px);
  top: 53.13px;
  left: 19px;
  background-color: #FD614D;
  z-index: 200;
}

.loader.active {
  animation: loading 1s forwards;
}

.green_flake {
  position: absolute;
  right: 140px;
  top: 69px;
}

@keyframes loading {
  50% {
    width: calc(100% - 19px);
  }
  100% {
    width: 0;
  }
}

@media screen and (max-width: 1640px) {
  .facilities {
    padding: 69px 50px 138px 178px;
  }
}

@media screen and (max-height: 850px) {
  .facilities {
    padding: 39px 50px 138px 178px;
  }
}

@media screen and (max-width: 992px) {
  .green_flake {
    display: none;
  }

  .facilities {
    padding: 89px 16px 50px 16px;
  }

  .loader {
    display: none;
  }
}
